.opening{
  margin-top: 86px;
}
.content-text{
  width: 95%;
  text-align: center;
  margin: 0 auto;
  @include respond-to(sm) {
    width: 400px;
  }
  @include respond-to(smd) {
    width: 500px;
  }
  h1{
    font-size: 20px;
  }
  p{
    font-size: 14px;
  }
}
// .carousel{
//   .slick-list {
//     margin: 0 -5px;
//   }
//
//   .slick-slide {
//     margin: 0px 5px;
//   }
// }

@mixin btn-base($bkcolor: $green, $hbkcolor: $green2, $txtcolor: $white, $htxtcolor: $white) {
  font-size: 14px;
  color: $txtcolor;
  font-weight: 600;
  padding: 12px 35px;
  border-radius: 20px;
  background: $bkcolor;
  &:hover{
    background: $hbkcolor;
    color: $htxtcolor;
  }
}
@mixin btn-base-inverted() {
  font-size: 14px;
  color: $white;
  font-weight: 600;
  padding: 12px 35px;
  border-radius: 20px;
  background: $green2;
  &:hover{
    background: $green;
  }
}

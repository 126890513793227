.footer {
	background: $black;
	margin-top: 80px;
	padding: 65px 0 40px;
	.footer-in{
		width: 360px;
		margin: 0 auto;
		text-align: center;
		.social-list{
			padding: 0;
			li{
				display: inline-flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				margin: 0 8px;
			}
		}
		hr{
			height: 1px;
			background: rgba($white, 0.2);
			border: 0;
		}
		p{
			font-size: 10px;
			letter-spacing: 2px;
			color: $white;
			margin: 20px 0 10px;
		}
		a{
			font-size: 10px;
			text-decoration: underline;
			color: $white;
		}
	}
}

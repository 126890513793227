.block-txt{

}
.form{
	margin-bottom: 90px;
}
.block-txt-2{
	margin-top: 90px;
}
.content-text{
	.col-text{
		h1, h2, h3, h4, h5, h6{
			font-family: $goldenbook;
			font-weight: 400;
			font-size: 20px;
			letter-spacing: 2px;
		}
		p{
			font-size: 14px;
			font-family: $brother;
			letter-spacing: 0.3px;
		}
		a{
			&.ldm-btn{
				background: $black;
				min-width: 160px;
				letter-spacing: 1px;
				font-size: 10px;
				letter-spacing: 2px;
				color: $white;
				font-family: $brother;
				padding: 6px 0px;
				display: inline-flex;
				text-align: center;
				margin: 15px 0 0 0;
				align-items: center;
				justify-content: center;
			}
		}
		form{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin-top: 40px;
			.ldm-btn{
				background: $black;
				min-width: 160px;
				letter-spacing: 1px;
				font-size: 10px;
				letter-spacing: 2px;
				color: $white;
				font-family: $brother;
				padding: 6px 0px;
				display: inline-flex;
				text-align: center;
				margin: 15px 0 0 0;
				align-items: center;
				justify-content: center;
				&:hover{

				}
			}
			input{
				width: calc(50% - 7.5px);
				border: 1px solid $black;
				margin-bottom: 15px;
				padding: 5px 10px;
				letter-spacing: 2px;
				font-size: 10px;
				font-family: $goldenbook;
				&.right{
					margin-left: 7.5px;
				}
				&.left{
					margin-right: 7.5px;
				}
				&.ldm-btn{
					background: $black;
					min-width: 160px;
					letter-spacing: 1px;
					font-size: 10px;
					letter-spacing: 2px;
					color: $white;
					font-family: $brother;
					padding: 6px 0px;
					display: inline-flex;
					text-align: center;
					margin: 15px 0 0 0;
					align-items: center;
					justify-content: center;
					&:hover{

					}
				}
			}
			textarea{
				width: 100%;
				padding: 5px 10px;
				letter-spacing: 2px;
				font-size: 10px;
				font-family: $goldenbook;
			}
		}

	}
	&.boxes{
		display: flex;
		flex-wrap: wrap;
	}
	.col-box{
		width: calc(100%);
		background: $white;
		padding: 40px 5px;
		margin-bottom: 2px;
		@include respond-to(sm) {
	    width: calc(50% - 1px);
	  }
		&:nth-child(1){
			@include respond-to(sm) {
				margin-right: 1px;
			}
		}
		&:nth-child(2){
			@include respond-to(sm) {
				margin-left: 1px;
			}
		}
		h3{
			font-size: 20px;
			font-family: $goldenbook;
			font-weight: 400;
			letter-spacing: 2px;
			margin-bottom: 20px;
		}
		p{
			font-size: 13px;
			a{
				color: $black;
			}
		}
	}
}
#error_message{
	margin-top: 30px;
	h4{
		text-transform: uppercase;
		font-size: 20px;
		font-family: $goldenbook;
		font-weight: 400;
		letter-spacing: 2px;
		margin-bottom: 20px;
	}
	ul{
		font-size: 11px;
		margin-top: 20px;
		li{
			list-style: none;
			font-size: 11px;
			font-family: $brother;
			letter-spacing: 0.3px;
		}
	}
}
#success_message{
	margin-top: 30px;
	h4{
		text-transform: uppercase;
		font-size: 20px;
		font-family: $goldenbook;
		font-weight: 400;
		letter-spacing: 2px;
		margin-bottom: 20px;
	}
	ul{
		font-size: 11px;
		margin-top: 20px;
		li{
			list-style: none;
			font-size: 11px;
			font-family: $brother;
			letter-spacing: 0.3px;
		}
	}
}

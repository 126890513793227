@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: #b1b1b1 !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 30px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 15px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 999999;
    li {
        position: relative;
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0 0;
        padding: 0;
        cursor: pointer;
        @include respond-to(md){
          height: 20px;
          width: 20px;
          margin: 0 0.5px;;
        }
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 12px;
            width: 12px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 2px;
            cursor: pointer;
            @include respond-to(md){
              height: 20px;
              width: 20px;
            }
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 12px;
                height: 12px;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                line-height: 10px;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                @include respond-to(md){
                  height: 20px;
                  width: 20px;
                  line-height: 20px;
                }
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}


.carousel{
  display: flex;
  .slick-slide{
    img{
      max-width: 100%;
    }
  }
  &.center{
    margin-bottom: 60px;
    .slick-slide {
      margin: 0 2px;
    }
  }
}
.carousel-two{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
  @include respond-to(smd) {
    width: 1024px;
  }
}
.singleslides{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
  @include respond-to(smd) {
    width: 1024px;
  }
}

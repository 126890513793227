//------------------------------------------------------------layout
$hamb-w: 35px;
$hamb-h: 20px;
$hamb-l-h: 2px;
$hamb-l-w: 35px;

header{
	// GENERIC STYLES
	&.main-header{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: $zindex-max - 1;
		background: $white;
		padding: 10px 0;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		.logo{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			margin: 10px 0 6px;
			a{
				height: 50px;
			}
			img{
				max-height: 100%;
				width: auto;
			}
		}
		// LIST STYLE
		&.v_nav-list{
			.nav-menu{
				text-align: right;
				ul{
					display: block;
					margin: 0;
					padding: 0;
					display: none;
					@include respond-to(md) {
						display: block;
					}
					li{
						display: inline-block;
						color: $white;
						margin: 0 10px;
						&:last-child{
							margin: 0 0 0 10px;
						}
						a{
							color: inherit;
							font-size: inherit;
						}
					}
				}
			}
			&.navopen {
				display: block;
				@include basetransition($timing-fast);
				@include respond-to(md) {
					display: none;
				}
			}
			.hamburger-cont{
				display: inline-block;
				@include respond-to(md) {
					display: none;
				}
			}
		}
		// HAMBURGER STYLE
		&.v_hamburger{
			.hamburger-cont{
				display: inline-block;
			}
			.nav-menu{
				text-align: right;
				ul{
					display: none;
				}
			}
		}
	}
	.hamburger-cont{
  	position: relative;
		width: $hamb-w;
		height: $hamb-h;
		&.navopen{
			.hamburger{
				.middle-line{
					background: transparent;
					opacity: 0;
					@include basetransition($timing-fast);
				}
				&:after{
					transform: rotate(-45deg);
					top: 10px !important;
					@include basetransition($timing-fast);
				}
				&:before{
					transform: rotate(45deg);
					top: 10px !important;
					@include basetransition($timing-fast);
				}
			}
		}
		.hamburger{
			position: relative;
			background: transparent;
			border: 0;
	    width: 100%;
  		height: 100%;
			&.two-lines_mod{
				.middle-line{
					display: none;
				}
				&:after{
					top: 10px;
			    right: 0;
				}
				&:before{
					bottom: 0px;
			    right: 0;
				}
			}
			&.three-lines_mod{
				.middle-line{
					right: 0;
				}
				&:after{
					top: 0;
			    right: 0;
				}
				&:before{
					bottom: 0px;
			    right: 0;
				}
			}
			.middle-line{
				height: $hamb-l-h;
				width: $hamb-l-w;
				position: absolute;
				background: $white;
				@include basetransition($timing-fast);
			}
			&:after{
				content: '';
				height: $hamb-l-h;
				width: $hamb-l-w;
				background: $white;
				position: absolute;
		    @include basetransition($timing-fast);
			}
			&:before{
				content: '';
				height: $hamb-l-h;
				width: $hamb-l-w;
				background: $white;
				position: absolute;
		    @include basetransition($timing-fast);
			}
		}
	}
}

// MOBILE MENU STYLE AND VARIATIONS
.mobile-menu{
	position: fixed;
	z-index: $zindex-max;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: $black;
	color: $white;
	display: none;
	@include basetransition($timing-fast);
	.row{
 		min-height: 100vh;
	}
	&.center_mod{
		ul{
			text-align: center;
		}
	}
	&.left_mod{
		ul{
			text-align: left;
		}
	}
	&.right_mod{
		ul{
			text-align: right;
		}
	}
	ul{
		padding: 0;
		li{
			display: block;
			color: $white;
			a{
				color: inherit;
				font-size: inherit;
			}
		}
	}
	&.v_nav-list{
		&.navopen {
			display: block;
			@include respond-to(md) {
				display: none;
			}
		}
	}
	&.v_hamburger{
		&.navopen {
			display: block;
		}
	}
	&.navopen {
		@include basetransition($timing-fast);
	}
}

//------------------------------------------------------------layout###

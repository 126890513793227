//---------------------------------------------colors
$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base:#4aa3df;
$body_bg: #FAFAFA;

$blackcustom: #4A4A4A;
$grey: #F8F6F3;
$grey2: #C4C4C4;
$grey3: #9B9B9B;
$red: #E21E2F;
$blue: #2086C7;
$bluedark: #134E89;
$orange: #F18B00;
$yellow: #F9C213;
$yellow2: #E4B633;
$green: #4AB697;
$green2: #3F997F;
$lilla: #C18ABC;
$magenta: #CF196F;
$acqua: #43A1AF;

$input_placeholder_color: $grey;

// FONT COLORS
@mixin maincolor() {
	color: $black;
}
@mixin secondcolor() {
	color: $black;
}
@mixin secondcolor() {
	color: $black;
}

// BACK COLORS
@mixin maincolorback() {
	background-color: $black;
}
@mixin secondcolorback() {
	background-color: $black;
}
@mixin thirdcolorback() {
	background-color: $black;
}

// GENERIC COLORS
@mixin blackback() {
	background-color: $black;
}
@mixin mainblack() {
	color: $black;
}


$colormap: (
	red: $red,
	blue: $blue,
	green: $green
);

@mixin colorclasses($type) {
	@if $type == background {
		@each $name, $color in $colormap {
	    &.#{$name} {
	      background-color: $color;
	    }
	  }
	}
	@if $type == text {
		@each $name, $color in $colormap {
	    &.#{$name} {
	      color: $color;
	    }
	  }
	}
	@if $type == border {
		@each $name, $color in $colormap {
	    &.#{$name} {
	      border-color: $color;
	    }
	  }
	}
}


//---------------------------------------------end colors
